// cache-2
import React, { useState } from "react";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { generateSync } from "text-to-image";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  withMobileDialog,
  withStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

import withTranslator from "./hocs/withTranslator";
import withSnackbar from "./hocs/withSnackbar";
import "./PdfViewerWithSignature.css";

const signTermsOfAdhesionMutation = loader(
  "../mutations/SignTermsOfAdhesion.graphql"
);

const styles = (theme) => ({
  alertMessage: {
    "& .MuiAlert-message": {
      width: "100%",
    },
  },
});

const PdfViewerWithSignature = (props) => {
  const { pdfUrl, signersName, open, fullScreen, i18n, classes } = props;

  const [page, setPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(!!open);
  const [signingDialogOpen, setSigningDialogOpen] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [], // removes side toolbar
    renderToolbar: (toolbarSlot) => {
      // removes top toolbar
      return [];
    },
  });

  const submitSignedDocument = () => {
    const signature = generateSync(signersName, {
      // debug: true,
      // maxWidth: 400,
      fontSize: 50,
      fontFamily: "Great Vibes, cursive",
      // lineHeight: 50,
      // margin: 5,
      bgColor: "transparent",
      textColor: "black",
    });

    if (!signature) {
      alert("Por favor, assine todos os campos");
      return;
    }

    const { signTermsOfAdhesion, termsOfAdhesionId, snackbar, secretKey } =
      props;
    signTermsOfAdhesion({
      variables: {
        input: {
          brTermsOfAdhesionId: termsOfAdhesionId,
          signature,
          secretKey,
        },
      },
    }).then((res) => {
      setSigningDialogOpen(null);
      setDialogOpen(false);
      snackbar.setState({
        snackbarMessage: `Documento assinado com sucesso`,
        snackbarOpen: true,
        snackbarVariant: "success",
      });
    });
  };

  const handlePageChange = (page) => {
    const pageNumber = page.currentPage + 1;
    setPage(pageNumber);
    if (!totalPageCount) {
      setTotalPageCount(page.doc.numPages);
    }
  };

  const renderSigningDialog = () => {
    if (!signingDialogOpen) return null;
    const title = `Assine abaixo:`;
    return (
      <Dialog open={!!signingDialogOpen} fullScreen={fullScreen}>
        <Grid
          container
          // alignItems="center"
          direction="column"
          style={{ padding: "1rem" }}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setSigningDialogOpen(null)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              Ao marcar a caixa abaixo, declaro que li e concordo com os termos
              e condições descritos no contrato.
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    setCheckboxChecked(event.target.checked);
                  }}
                  checked={checkboxChecked}
                />
              }
              label="Eu concordo com os termos de adesão."
            />
          </Grid>
          <Grid container item alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={() => {
                  submitSignedDocument();
                }}
                variant="contained"
                color="secondary"
                disabled={!checkboxChecked}
              >
                {i18n.t("submitDocument", "Submit document")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  return (
    <Dialog open={!!dialogOpen} style={{ overflow: "scroll" }} fullScreen>
      <Grid container style={{ padding: "1rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: "0 20px" }}
        >
          <Grid item>
            <Typography variant="h6">
              {fullScreen
                ? "Assine seus termos"
                : "Assine seus termos de adesão"}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                // if (formCompleted) {
                //   submitSignedDocument();
                // } else {
                setDialogOpen(false);
                // }
              }}
              // variant={formCompleted ? "contained" : null}
              // color={formCompleted ? "primary" : "default"}
            >
              {i18n.t("signLater", "Sign later")}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ padding: "0 20px" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={8} style={{ minHeight: "4rem" }}>
            <Alert
              severity="warning"
              icon={false}
              className={classes.alertMessage}
            >
              <Grid
                container
                style={{ width: "100%" }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  {i18n.t(
                    "yourSignatureIsRequired",
                    "Your signature is required"
                  )}
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      setSigningDialogOpen(true);
                    }}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    {i18n.t("signAllPages", "Sign all pages")}
                  </Button>
                </Grid>
              </Grid>
            </Alert>
          </Grid>
          {renderSigningDialog()}
          <Grid xs={12} md={8}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
            >
              <Grid
                item
                style={{
                  width: "100%",
                  height: "600px",
                  border: "1px solid #ccc",
                  marginBottom: "20px",
                }}
              >
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                  onPageChange={(page) => {
                    handlePageChange(page);
                  }}
                />
              </Grid>
            </Worker>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(
  withSnackbar(
    withMobileDialog()(
      graphql(signTermsOfAdhesionMutation, {
        name: "signTermsOfAdhesion",
      })(withTranslator(PdfViewerWithSignature))
    )
  )
);
