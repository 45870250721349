import React, { Component, Fragment } from "react";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

import {
  Button,
  CircularProgress,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Grid,
  Select,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Slide,
  TextField,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Close, Delete, Edit, Warning } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import validator from "validator";

import withSnackbar from "./hocs/withSnackbar";
import withTranslator from "./hocs/withTranslator";

import { constants } from "../global";

const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const removeBrContactMutation = loader("../mutations/RemoveBrContact.graphql");
const updateBrContactRoleMutation = loader(
  "../mutations/UpdateBrContactRole.graphql"
);
const userManagementDialogQuery = loader(
  "../queries/UserManagementDialog.graphql"
);

const styles = (theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
});

class UserManagementDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false, // eslint-disable-line
    };
    this.update = this.update.bind(this);
    this.removeBrContact = this.removeBrContact.bind(this);
    this.updateBrContactRole = this.updateBrContactRole.bind(this);
  }

  update(attr) {
    const that = this;
    return (event) => {
      that.setState({ [attr]: event.target.value });
    };
  }

  removeBrContact() {
    this.setState({ loading: true });
    const { removeBrContact, snackbar, data } = this.props;
    const { deleteUser } = this.state;
    removeBrContact({
      variables: { id: deleteUser.id },
    })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
        console.log(errors);
        this.setState({ loading: false });
        snackbar.setState({
          snackbarMessage: `Sorry, error removing contact! Please try again later.`,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      })
      .then(async () => {
        await data.refetch();
        this.setState({ loading: false, deleteUser: null });
        snackbar.setState({
          snackbarMessage: `Contact removed!`,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      });
  }

  updateBrContactRole() {
    this.setState({ loading: true });
    const { updateBrContactRole, snackbar, data } = this.props;
    const { editUser, editUserRole, editUserPrimaryContactFlg } = this.state;
    const changeObj = { id: editUser.id };
    if (editUserRole) {
      changeObj.role = editUserRole;
    }
    if (typeof editUserPrimaryContactFlg === "boolean") {
      changeObj.primaryContactFlg = editUserPrimaryContactFlg;
    }
    updateBrContactRole({
      variables: { input: changeObj },
    })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
        console.log(errors);
        this.setState({ loading: false });
        snackbar.setState({
          snackbarMessage: `Sorry, error updating contact role! Please try again later.`,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      })
      .then(async () => {
        await data.refetch();
        this.setState({
          loading: false,
          editUser: null,
          editUserRole: null,
          editUserPrimaryContactFlg: null,
        });
        snackbar.setState({
          snackbarMessage: `Contact updated!`,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      });
  }
  renderContent() {
    const { data, theme, classes, i18n } = this.props;
    const {
      editUser,
      deleteUser,
      addUserOpen,
      editUserRole,
      addUserFirstName,
      addUserLastName,
      addUserEmail,
      addUserRole,
      loading,
      editUserPrimaryContactFlg,
    } = this.state;
    {
      console.log(
        !addUserRole,
        !addUserFirstName,
        !addUserLastName,
        !addUserEmail,
        !validator.isEmail(addUserEmail || "")
      );
    }
    return (
      <>
        {addUserOpen && (
          <Collapse in={!!addUserOpen}>
            <Slide direction="up" in={!!addUserOpen} style={{ zIndex: 10000 }}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ margin: "1rem 0" }}
              >
                <Grid item>
                  <Typography variant="h6" paragraph>
                    Please enter your new user:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <TextField
                        required
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        value={addUserFirstName}
                        onChange={(event) => {
                          this.setState({
                            addUserFirstName: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        label="Last Name"
                        variant="outlined"
                        value={addUserLastName}
                        onChange={(event) => {
                          this.setState({
                            addUserLastName: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        label="Email"
                        variant="outlined"
                        error={
                          addUserEmail?.length > 3 &&
                          !validator.isEmail(addUserEmail)
                        }
                        value={addUserEmail}
                        onChange={(event) => {
                          this.setState({
                            addUserEmail: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl
                        required
                        fullWidth
                        className={classes.formControl}
                        value={addUserRole}
                      >
                        <InputLabel
                          htmlFor="role-select"
                          required
                          style={{ marginLeft: "14px", marginTop: "-5px" }}
                        >
                          Role
                        </InputLabel>
                        <Select
                          required
                          labelWidth={40}
                          id="role-select"
                          variant="outlined"
                          size="large"
                          fullWidth
                          select
                          onChange={(event) => {
                            this.setState({ addUserRole: event.target.value });
                          }}
                        >
                          {constants.roles.map((role) => (
                            <MenuItem value={role.value}>{role.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        disabled={
                          loading ||
                          !addUserRole ||
                          !addUserFirstName ||
                          !addUserLastName ||
                          !addUserEmail ||
                          !validator.isEmail(addUserEmail)
                        }
                        onClick={() => alert("hit it")}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.setState({
                            addUserOpen: false,
                            addUserRole: null,
                            addUserFirstName: null,
                            addUserLastName: null,
                            addUserEmail: null,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Slide>
          </Collapse>
        )}
        {editUser && (
          <Collapse in={!!editUser}>
            <Slide direction="up" in={!!editUser} style={{ zIndex: 10000 }}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ margin: "1rem 0" }}
              >
                <Grid item>
                  <Typography variant="h6" paragraph>
                    Please select the new role for this user:
                  </Typography>
                  <Select
                    labelId="label"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "1rem" }}
                    value={editUserRole || editUser?.role}
                    onChange={(event) =>
                      this.setState({ editUserRole: event.target.value })
                    }
                    select
                  >
                    {constants.roles.map((role) => (
                      <MenuItem value={role.value}>
                        {role.label}
                        {/* <ListItemText
                        primary={role.label}
                        secondary={role.description}
                      /> */}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          this.setState({
                            editUserPrimaryContactFlg: event.target.checked,
                          });
                        }}
                        checked={
                          typeof editUserPrimaryContactFlg === "boolean"
                            ? editUserPrimaryContactFlg
                            : editUser?.primaryContactFlg
                        }
                      />
                    }
                    label="Billing Contact"
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        disabled={
                          (!editUserRole || editUserRole === editUser?.role) &&
                          (typeof editUserPrimaryContactFlg !== "boolean" ||
                            editUserPrimaryContactFlg ===
                              editUser?.primaryContactFlg)
                        }
                        onClick={this.updateBrContactRole}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.setState({ editUser: null, editUserRole: null })
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Slide>
          </Collapse>
        )}
        <Collapse in={!!deleteUser}>
          <Slide direction="up" in={!!deleteUser} style={{ zIndex: 10000 }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ margin: "1rem 0" }}
            >
              <Grid item>
                <Typography variant="h6" paragraph>
                  <Grid container alignItems="center">
                    <Warning
                      style={{
                        marginRight: "1rem",
                        color: theme.palette.warning.main,
                      }}
                    />
                    Are you sure you want to delete this user?
                  </Grid>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      color="primary"
                      style={{ backgroundColor: theme.palette.error.main }}
                      variant="contained"
                      onClick={this.removeBrContact}
                      disable={loading}
                    >
                      Yes, remove
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disable={loading}
                      onClick={() => this.setState({ deleteUser: null })}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Slide>
        </Collapse>
        <Collapse in={!editUser && !deleteUser && !addUserOpen}>
          <Grid container>
            <Grid item xs={12}>
              <List>
                {data.brCustomer.brContactsBrCustomers.map((user) => (
                  <ListItem disableGutters style={{ paddingRight: "84px" }}>
                    <ListItemText
                      primary={user.brContact.fullName}
                      secondary={`${capitalizeFirstLetter(user.role)}${
                        user.primaryContactFlg ? " - Primary Contact" : ""
                      }`}
                    />
                    <ListItemSecondaryAction style={{ right: "6px" }}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        // size="small"
                        color="primary"
                        style={{ marginRight: "0" }}
                        onClick={() => this.setState({ editUser: user })}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        // size="small"
                        disabled={user.brContact.id === data?.me?.brContact?.id}
                        color="primary"
                        onClick={() => this.setState({ deleteUser: user })}
                      >
                        <Delete
                          style={{
                            color:
                              user.brContact.id === data?.me?.brContact?.id
                                ? "lightgrey"
                                : theme.palette.error.main,
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                <Grid container justifyContent="flex-end">
                  <Button
                    startIcon={<Add />}
                    color="secondary"
                    variant="contained"
                    style={{ marginTop: ".5rem" }}
                    onClick={() => this.setState({ addUserOpen: true })}
                  >
                    {i18n.t("addUser", "Add User")}
                  </Button>
                </Grid>
              </List>
            </Grid>
          </Grid>
        </Collapse>
      </>
    );
  }

  render() {
    const { i18n, fullScreen, open, onClose, data } = this.props;
    if (data.loading) {
      return <CircularProgress />;
    }
    return (
      <Dialog
        fullScreen={fullScreen}
        open={!!open}
        onClose={onClose}
        fullWidth
        // maxWidth={"md"}
      >
        <DialogTitle style={{ textAlign: "right" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="column" alignItems="flex-start">
                {i18n.t("manageUser", "Manage Users")}
                <Typography variant="caption">
                  {data.brCustomer.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Divider style={{ margin: "1rem 0 0" }} />
        </DialogTitle>
        <DialogContent>
          {data.loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ minWidth: "240px", minHeight: "12rem", width: "100%" }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            this.renderContent()
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(
  graphql(userManagementDialogQuery)(
    graphql(removeBrContactMutation, { name: "removeBrContact" })(
      graphql(updateBrContactRoleMutation, { name: "updateBrContactRole" })(
        withStyles(styles, { withTheme: true })(
          withMobileDialog()(withTranslator(UserManagementDialog))
        )
      )
    )
  )
);
